<template>
  <div>
    <v-row>
      <v-col>
        <h4>Password</h4>
        <v-text-field v-model="password" label="Generated password"></v-text-field>
        <v-btn @click="onGenerate" class="mr-5">Generate</v-btn>
        <v-btn @click="onCopyPass" :disabled="this.password.length == 0">Clipboard</v-btn>
      </v-col>
      <v-col>
        <h4>Settings</h4>
        <br>
        <div class="text-caption">Length</div>
        <v-slider v-model="gLength" min="4" max="16" thumb-label="always" :thumb-size="20"></v-slider>
        <template >
          <v-checkbox v-for="item in characters" :key="item.name" v-model="item.checked" :label="item.name" :input-value="item.value"
            class="mt-0 mb-0"></v-checkbox>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      characters: [
        {
          name: "Lowercase",
          value: "abcdefghijklmnopqrstuvwxyz",
          checked: true,
        },
        {
          name: "Uppercase",
          value: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
          checked: true,
        },
        {
          name: "Numbers",
          value: "0123456789",
          checked: true,
        },
        {
          name: "Special Characters",
          value: "_-+=)(*&^%$#@!`~",
          checked: true,
        },
      ],
      password: "",
      gLength: 10,
    };
  },
  props: {
    generatedPassword: {
      default: "",
    },
  },
  methods: {
    onGenerate() {
      console.log("LENGTH", this.gLength)
      let result = "";
      let charactersVal = "";
      for (var j = 0; j < this.characters.length; j++) {
        if (this.characters[j].checked) {
          charactersVal += this.characters[j].value;
        }
      }
      for (var i = 0; i < this.gLength; i++) {
        result += charactersVal.charAt(Math.floor(Math.random() * charactersVal.length));
      }
      this.password = result;

      this.$emit("update:generatedPassword", this.password);
    },
    onCopyPass() {
      let textToCopy = this.password;
      try {
        // 1) Copy text
        navigator.clipboard.writeText(textToCopy);

        // 2) Catch errors
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }
  },
};
</script>
<style scoped>
.v-input--checkbox {
  height: 30px;
}

.v-input__slider {
  margin-bottom: -10px;
}
</style>
