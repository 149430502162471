import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
// import VueTextareaAutosize from "vue-textarea-autosize";
import infiniteScroll from "vue-infinite-scroll";
import { createPinia, PiniaVuePlugin } from 'pinia'

import router from "./router";
import firebase from 'firebase/app';

// Vue.use(VueTextareaAutosize);
Vue.use(infiniteScroll);

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.config.productionTip = false;

// Vue.use(vuetify);

import { usePermissionGroupStore } from "./store/PermissionGroup";
import { usePasswordStore } from "./store/Password";
import { useUserStore } from "./store/User";

var vm = new Vue({
  vuetify,
  router,
  pinia,
  // mixins: [myMixin],
  methods: {
    initialize() {
      // passwords are bound after user was loaded in bindUserDb
      usePasswordStore().initGetKey();
      usePermissionGroupStore().bindPermissionGroups();
      useUserStore().bindAllUserDb();
      useUserStore().updateUser();
    },
  },
  created() {
    var vm = this;
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        useUserStore().userLogin(user);

        try {
          await useUserStore().bindUserDb();
          useUserStore().bindUserMyDb();
          

          vm.initialize();
          router.push("/");

        } catch (err) {
          console.warn("Could not bind user", user.email, err);
          if (user.metadata.creationTime === user.metadata.lastSignInTime) {
            console.warn("User was created at", user.metadata.creationTime, "Reload page...");
            router.push("/");
          } else {
            alert("User " + user.email + " is invalid. If you logged in the first time, please try again in a few seconds.");
            useUserStore().userLogout();
          }
        }

      } else {
        useUserStore().userLogout();
      }
    });

  },

  render: (h) => h(App),
});
vm.$mount("#app");

Vue.mixin({
  methods: {
    fallbackCopyTextToClipboard: (text) => {
      var textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard: (text) => {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
          this.fallbackCopyTextToClipboard(text);
        }
      );
    },
  },
});
// var myMixin = {

// };