import { defineStore } from 'pinia'
import {
  database, fireFunctions
} from "./database";
import firebase from "firebase/app";
import { usePasswordStore } from "./Password";

/**
 * @todo Integrate Firestore Ref-Binding (Vuefire) on each action * 
 */
export const useUserStore = defineStore('UserStore', {
  state: () => {
    return {
      all: {},
      currentUser: null,
      currentUserDb: null,
      currentUserMyDb: null,
      allUser: [],
      groupIds: null,
    }
  },
  getters: {
    user: (state) => {
      console.log("GET user", state.currentUserDb);
      return state.currentUserDb;
    },
    isLoggedIn: (state) => {
      return state.currentUser !== null;
    },
    userId: (state) => {
      return state.currentUserDb.id;
    },
    userEmail: (state) => {
      return state.currentUserDb.email;
    },
    isAdmin: (state) => {
      return (state.currentUserDb && !!state.currentUserDb.admin);
    },
    isAdminSecurity: (state) => {
      return (state.currentUserDb && !!state.currentUserDb.adminSecurity);
    },
    isGroupAdmin: (state) => {
      return !!state.currentUserDb.admin || !!state.currentUserDb.adminGroup;
    },
    userGroups: (state) => {
      if (state.currentUserDb && state.currentUserDb.groupIds) {
        return (state.currentUserDb.groupIds.concat(`private:${state.currentUserDb.id}`));
      }

      return [];
    },
  },
  actions: {
    userLogin: function (user) {
      this.currentUser = user;
    },
    userLogout: function () {
      this.currentUser = null;
    },
    bindUserDb: function () {
      return new Promise((resolve, reject) => {
        let lastGroupIds = null; // memorize last permission group list to trigger a password reload on a group membership change
        database.collection('User').doc(firebase.auth().currentUser.uid).onSnapshot((doc) => {
          this.currentUserDb = doc.data();
          // group membership changed. (re)load passwords
          if(!lastGroupIds || JSON.stringify(lastGroupIds) != JSON.stringify(this.currentUserDb.groupIds)) {
            lastGroupIds = this.currentUserDb.groupIds;
            usePasswordStore().bindPWs(); // group settings could have been changed
          }

          resolve();
        });
      });
    },
    bindUserMyDb: function () {
      database.collection('User').doc(firebase.auth().currentUser.uid).collection("myProfile").doc("data").onSnapshot((doc) => {
        this.currentUserMyDb = doc.data();
      })
    },
    bindAllUserDb: function () {
      let userCollection = "UserList";
      if (this.currentUserDb && (this.currentUserDb.admin || this.currentUserDb.adminSecurity)) {
        userCollection = "User";
      }

      database.collection(userCollection).onSnapshot((snap) => {
        this.allUser = snap.docs.map(doc => doc.data());
        console.debug("User: Found", this.allUser.length, "users in", userCollection);
      });
    },
    changeValueOfAdmin: function (details) {
      database.collection('User').doc(details.id).update({
        admin: !details.admin
      })
    },
    changeValueOfAdminSecurity: function (details) {
      database.collection('User').doc(details.id).update({
        adminSecurity: !details.adminSecurity
      })
    },
    changeValueOfAdminGroup: function (details) {
      database.collection('User').doc(details.id).update({
        adminGroup: !details.adminGroup
      })
    },
    setLeaveDate: function (user) {
      // call remote function to update leaveDate
      var updateLastLogin = fireFunctions.httpsCallable("user-updateLeaveDate");
      updateLastLogin({ uid: user.id, leaveDate: user.leaveDate });
    },
    /**
   * update user based on the identity provider data
   * 
   * @param {*} context 
   */
    updateUser: function () {
      if (this.currentUser && this.currentUser.providerData[0] && this.currentUser.providerData[0].providerId == 'google.com') {
        let providerData = this.currentUser.providerData[0];
        let changed = false;
        // add the Google Id if not yet set
        if (!this.currentUserDb.googleId && providerData.uid) {
          console.debug("Google Id", providerData.uid, "missing in user", this.currentUserDb.email);
          changed = true;
        }

        // update the email address if changed
        if (providerData.email != this.currentUserDb.email) {
          console.debug("Email changed from", this.currentUserDb.email, "to", providerData.email);
          changed = true;
        }

        // update the display name if changed
        if (providerData.displayName != this.currentUserDb.displayName) {
          console.debug("Display name changed from", this.currentUserDb.displayName, "to", providerData.displayName);
          changed = true;
        }

        // trigger user data update
        if (changed) {
          var updateData = fireFunctions.httpsCallable("user-updateData");
          updateData(providerData).then((r) => {
            console.log("Updated user", r);
          }).catch((e) => console.error(e));
        }

        // call remote function to update login
        var updateLastLogin = fireFunctions.httpsCallable("user-updateLastLogin");
        updateLastLogin();
      }
    },
    deleteUser: async function (user) {
      database.collection('User').doc(user.id).delete();
      database.collection('UserList').doc(user.id).delete();
    }
  }
});