import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import dbConfig from "../../dbConfig.json";

firebase.initializeApp(dbConfig);

const database = firebase.firestore();
const fireFunctions = firebase.app().functions("europe-west3");
export {
  database,
  fireFunctions
};
