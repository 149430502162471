<template>
  <v-app id="appMain">
    <app-navigation></app-navigation>

    <!-- Sizes your content based upon application components -->
    <v-main class="ma-0 pa-0">
      <v-main transition="slide-x-transition">
        <v-container fluid fill-height class="align-start">
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-main>
  </v-app>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";

export default {
  name: "App",
  components: {
    AppNavigation,
  },
};
</script>

<style lang="scss">
#appMain::before {
  background-image: url("../public/cw_gradient.png");
  opacity: 0.75;
  content: " ";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

html::-webkit-scrollbar {
  display: none;
}

.v-autocomplete__content {
  .v-list-item {
    min-height: 36px;

    .v-list-item__action {
      margin: 0 12px 0 0;
    }
    .v-list-item__content {
      .v-list-item__title {
        font-size: 0.875rem;
      }
    }
  }  
}
</style>
