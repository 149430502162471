<template>
  <v-container fluid>
    <v-row>
      <v-col
        align-self="start"
        cols="12"
        xl="6"
        lg="12"
        md="12"
        sm="12"
        xs="12"
      >
        <v-row>
          <v-layout>
            <ComponentPwTable :items="passwords" titleTable="Passwords" />
          </v-layout>
        </v-row>
      </v-col>
      <v-col cols="12" xl="6" lg="12" md="12" sm="12" xs="12">
        <v-row v-if="favorites.length">
          <v-layout class="weigth: 300px">
            <ComponentPwTable
              :items="favorites"
              titleTable="Favorites"
              createBtn="false"
            />
          </v-layout>
        </v-row>
        <v-row v-if="lastSeen.length">
          <v-layout class="mt-0">
            <ComponentPwTable
              :items="lastSeen"
              titleTable="Last seen"
              createBtn="false"
            />
          </v-layout>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-snackbar :timeout="timeout" v-model="snackbar">
        {{ snackbarText }}
        <v-btn color="info" @click.native="snackbar = false">Close</v-btn>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import ComponentPwTable from "./ComponentPwTable";
import { fireFunctions } from "../store/database";
import { usePasswordStore } from "../store/Password";
import { useUserStore } from "../store/User";

export default {
  name: "PWTable",
  data: () => ({
    newItem: false,
    filter: {
      company: "",
      info: "",
      mailLogin: "",
    },
    search: "",
    editDialog: false,
    dialog: false,
    snackbarText: "",
    snackbar: false,
    timeout: 2000,
  }),
  components: {
    ComponentPwTable,
  },
  computed: {
    ...mapState(useUserStore, ["currentUserDb"]),
    ...mapState(usePasswordStore, ["loading", "favorites", "lastSeen", "passwords"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {

  },

  methods: {
    logItem(item) {
      console.log(item);
    },
    showSnackbar(text) {
      // console.log(text);
      this.snackbarText = text;
      this.snackbar = true;
    },
    setloading(loadingStatus) {
      usePasswordStore().loading = loadingStatus;
    },

    deleteItem(itemId) {
      var r = confirm("Passwort löschen?");
      if (r == true) {
        usePasswordStore().deletePw(itemId);
        this.showSnackbar("Element gelöscht");
      }
    },
    getBackupCode(item) {
      const backupCodeArray = item.twoFactorBackupCodes.split(/\n/);
      const backupcode = backupCodeArray.shift();
      item.twoFactorBackupCodes =
        backupCodeArray.length > 0 ? backupCodeArray.join("\n") : "";
      // console.log(item.twoFactorBackupCodes);
      usePasswordStore().updatePw(item);
      this.copyTextToClipboard(backupcode);
      this.showSnackbar("Backup Code kopiert");
    },
    getKey(itemKey) {
      usePasswordStore().loading = true;
      var getKey = fireFunctions.httpsCallable("getKey");
      getKey(itemKey)
        .then((r) => {
          let pw = r.data;
          if (pw) {
            this.copyTextToClipboard(pw);
            this.showSnackbar("Password kopiert");
          } else {
            this.showSnackbar("kein Password hinterlegt");
          }
        })
        .catch((error) => {
          console.error(error);
          this.showSnackbar(error); // TODO funktioniert nicht;
        })
        .finally(() => usePasswordStore().loading = false);
    },
    getLogin(item) {
      usePasswordStore().initGetKey();
      this.copyTextToClipboard(item.mailLogin);
      this.showSnackbar("Login Email kopiert");
    },

    loadMore: function () {
      this.busy = true;

      setTimeout(() => {
        this.busy = false;
      }, 1000);
    },
  },
};
</script>
