<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="formattedLogs"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-row>
          <v-col>
            <v-text-field
              v-model="searchUser"
              label="Search User Mail in Firestore..."
              class="mx-4"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn class="primary" @click="updateWithSearch">
              Search
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              label="Search locally ..."
              class="mx-4"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
  <!-- :options.sync="options"
      :server-items-length="totalDesserts"
      :loading="loading" -->
</template>

<script>
import { mapState } from "pinia";
import { useLogStore } from "../store/Log";

export default {
  data() {
    return {
      searchUser: "",
      search: "",
      loading: true,
      options: {},
      headers: [
        {
          text: "Action",
          align: "start",
          sortable: false,
          value: "action",
          width: "200px",
        },
        { text: "Company", value: "password.company", width: "200px" },
        { text: "Info", value: "password.info", width: "200px" },
        { text: "Login", value: "password.mailLogin", width: "200px" },
        { text: "Time", value: "time", width: "170px" },
        { text: "User", value: "user", width: "200px" },
      ],
    };
  },

  computed: {
    ...mapState(useLogStore, ["formattedLogs"]),
  },

  mounted() {
    // check for active search query
    if (this.$route.query.uid) {
      useLogStore().bindLogs({"field": "userId", "value": this.$route.query.uid});      
    } else if (this.$route.query.email) {
      useLogStore().bindLogs({"field": "user", "value": this.$route.query.email});
    } else {
      useLogStore().bindLogs();
    }   
  },

  methods: {
    updateWithSearch() {
      useLogStore().bindLogs({"field": "user", "value": this.searchUser});
    },
  },
};
</script>
