import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"align-self":"start","cols":"12","xl":"6","lg":"12","md":"12","sm":"12","xs":"12"}},[_c(VRow,[_c(VLayout,[_c('ComponentPwTable',{attrs:{"items":_vm.passwords,"titleTable":"Passwords"}})],1)],1)],1),_c(VCol,{attrs:{"cols":"12","xl":"6","lg":"12","md":"12","sm":"12","xs":"12"}},[(_vm.favorites.length)?_c(VRow,[_c(VLayout,{staticClass:"weigth: 300px"},[_c('ComponentPwTable',{attrs:{"items":_vm.favorites,"titleTable":"Favorites","createBtn":"false"}})],1)],1):_vm._e(),(_vm.lastSeen.length)?_c(VRow,[_c(VLayout,{staticClass:"mt-0"},[_c('ComponentPwTable',{attrs:{"items":_vm.lastSeen,"titleTable":"Last seen","createBtn":"false"}})],1)],1):_vm._e()],1)],1),_c(VRow,[_c(VSnackbar,{attrs:{"timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c(VBtn,{attrs:{"color":"info"},nativeOn:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }