import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoggedIn)?_c('span',[_c(VAppBar,{attrs:{"app":"","clipped-left":"","color":"#fff","flat":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('span',{staticClass:"title ml-3 mr-5"},[_c('div',{staticClass:"float-left"},[_vm._v(" PCG "),_c('span',{staticClass:"font-weight-light"},[_vm._v("Password Safe")])]),(_vm.getStage() == 'dev')?_c('div',{staticClass:"float-left"},[_c('span',{staticClass:"red--text pl-4"},[_vm._v("Development Version")]),_c('span',{staticClass:"pl-4 caption"},[_c('a',{attrs:{"href":"https://cwpwsafe.web.app/","target":"_blank"}},[_vm._v("(Go to Production Version "),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v(")")],1)])]):_vm._e()]),_c(VSpacer),_c('span',{staticClass:"ml-3 mr-3 d-none d-sm-flex"},[_vm._v(_vm._s(_vm.userEmail))]),_c(VAvatar,{attrs:{"color":"indigo"},on:{"click":_vm.housekeeping}},[(!!_vm.thumbnailUrl)?_c('img',{attrs:{"src":_vm.thumbnailUrl}}):_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-account-circle")])],1)],1),_c(VNavigationDrawer,{attrs:{"app":"","clipped":"","color":"grey lighten-4","disable-resize-watcher":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,[_vm._l((_vm.items),function(item,i){return [(!item.adminOnly || _vm.isAdmin || _vm.isAdminSecurity)?[(item.link)?_c(VListItem,{key:i,attrs:{"to":item.link}},[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)],1):_vm._e(),(item.function)?_c(VListItem,{key:i,on:{"click":function($event){return _vm.call(item.function)}}},[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)],1):_vm._e()]:_vm._e()]})],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }