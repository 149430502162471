<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row class="text-center mt-16" justify="center">
          <v-btn v-if="!isLoggedIn" color="primary" @click="auth">Login With Google</v-btn>
          <v-btn v-if="isLoggedIn" color="secondary" @click="logout">Logout</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/*eslint-disable */
import firebase from 'firebase/app';
import 'firebase/auth'
import { mapState } from "pinia";
import { useUserStore } from '../../store/User';
var provider = new firebase.auth.GoogleAuthProvider();
// provider.addScope('https://www.googleapis.com/auth/contacts.readonly'); << INFO für die Zukunft
// provider.setCustomParameters({
//   hd: "cloudwuerdig.com",
// });

export default {
  data: () => ({
  }),
  methods: {
    auth() {
      const router = this.$router;
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function(result) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken; // für andere api calls... vorher scopes anfragen

          // The signed-in user info.
          var user = result.user;
        })
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
          console.log("error " + error.message);
        });
    },
    logout() {
      firebase.auth().signOut();
    },
  },
  computed: {
    ...mapState(useUserStore, ["isLoggedIn"]),
  },
};
/*eslint-enable */
</script>
