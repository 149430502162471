import { defineStore } from 'pinia'
import { database, fireFunctions } from "./database";
import firebase from "firebase/app";
import { useUserStore } from './User';

const GROUP_CHUNK_SIZE = 10;

/**
 * @todo Integrate Firestore Ref-Binding (Vuefire) on each action * 
 */
export const usePasswordStore = defineStore('PasswordStore', {
    state: () => {
        return {
            chunks: 1,
            allPasswordChunks: { "0": [] }, // more chunks will be added dynamically
            allPasswords: [], // distict list of all passwords
            loading: false,
            filterMine: true, // show only my passwords (for admins)
        }
    },
    getters: {// all passwords (admin: all, user: groups-filtered by query)        
        // filter passwords by group-memberships
        groupPWs() {
            return function (groupIds, includeArchived = false, includeUserPermissionId = false) {
                return this.allPasswords.filter((pw) => (
                    (includeArchived || !pw.archived) &&
                    ((includeUserPermissionId && Array.isArray(pw.permissionUserIds) && pw.permissionUserIds.includes(includeUserPermissionId)) ||
                        (Array.isArray(pw.permissionGroupIds) && pw.permissionGroupIds.filter((g) => groupIds.includes(g)).length > 0)))
                )
            }
        },
        // only show passwords that are in my groups
        myPasswords() {
            let userStore = useUserStore();
            if ((userStore.isAdmin || userStore.isAdminSecurity) && this.filterMine) {
                let groupIds = userStore.currentUserDb.groupIds.concat(`private:${userStore.userId}`)
                return this.groupPWs(groupIds, false, userStore.userId);
            } else {
                // filtering not needed, because it was already filtered in bindPWs()
                return this.allPasswords;
            }
        },
        // get passwords marked as favorites
        favorites() {
            let userStore = useUserStore();
            if (userStore.currentUserMyDb) {
                const favIds = userStore.currentUserMyDb.favorites || [];
                return this.allPasswords.filter((pw) => favIds.includes(pw.id) && !pw.archived);
            }
            return [];
        },
        // get passwords by last seen date
        lastSeen() {
            let userStore = useUserStore();
            if (userStore.currentUserDb) {
                let lastSeen = userStore.currentUserDb.lastSeen || [];
                const filtered = this.myPasswords.filter((pw) => lastSeen.includes(pw.id));
                const filteredIds = filtered.map((pw) => pw.id);
                lastSeen = lastSeen.filter((lSpW) => filteredIds.includes(lSpW));
                //&& !pw.archived
                return lastSeen.map((lSpW) => filtered.filter((pw) => pw.id === lSpW)[0]);
            }

            return [];
        },
        // get all passwords visible to me
        passwords() {
            let userStore = useUserStore();
            if (userStore.currentUserDb) {
                return this.myPasswords.filter((pw) => !pw.archived);
            }

            return [];
        },
        // get archived passwords
        archivedPWs() {
            return this.allPasswords.filter((pw) => pw.archived);
        },
        orphans() {
            return this.allPasswords.filter((pw) => (!pw.permissionGroupIds || pw.permissionGroupIds.length == 0) && (!pw.permissionUserIds || pw.permissionUserIds.length == 0));
        },
    },
    actions: {
        initGetKey: function () {
            console.log("init getKey...");
            var getKey = fireFunctions.httpsCallable("getKey");
            getKey("warmup").then(() => {
                console.log("finish getKey...");
            }).catch((e) => console.error(e));
        },
        createNewPw: function (newPw) {
            this.loading = true;
            var createPw = fireFunctions.httpsCallable("createNewPw");
            const pwObj = {
                company: newPw.company || "",
                domain: newPw.domain || "",
                mailLogin: newPw.mailLogin || "",
                pw: newPw.pw || "",
                info: newPw.info || "",
                permissionGroupIds: newPw.permissionGroupIds,
                permissionUserIds: newPw.permissionUserIds,
                private: newPw.private,
                link: newPw.link || "",
                twoFactorInfo: newPw.twoFactorInfo || "",
                twoFactorBackupCodes: newPw.twoFactorBackupCodes || "",
                otpEnabled: newPw.otpEnabled || false,
                otpSecret: newPw.otpSecret || "",                
                description: newPw.description || "",
            };

            createPw(pwObj)
                .then(function () {
                })
                .catch(function (error) {
                    console.error(error);
                })
                .finally(() => this.loading = false);
        },
        // firebase.auth().currentUser
        changeFavorite: function ({ pwId, isToAdd }) {
            const uid = firebase.auth().currentUser.uid;
            const doc = database.collection("User").doc(uid).collection("myProfile").doc("data").get().then(doc => {
                var updateType = isToAdd ? "arrayUnion" : "arrayRemove";
                if (!doc.exists) {
                    doc.ref.set({
                        favorites: isToAdd ? [pwId] : [],
                    });
                } else {
                    doc.ref.update({
                        favorites: firebase.firestore.FieldValue[updateType](pwId),
                    });
                }
            })
        },
        archivePw: function (data) {
            this.loading = true;
            var archivePw = fireFunctions.httpsCallable("password-archivePw");
            archivePw(data).then(() => console.log("Successfully (un)archived password"))
                .catch((e) => console.log(e))
                .finally(() => this.loading = false);
        },
        bindPWs: function () {
            this.loading = true;
            let userStore = useUserStore();
            let userGroup = structuredClone(userStore.userGroups);

            // `array-contains-any` condition requires a non-empty array
            if (!userGroup || userGroup.length === 0) {
                userGroup = [];
            }

            // admin: return all passwords regardeless of groups
            if (userStore.isAdmin === true || userStore.isAdminSecurity === true) {
                database.collection("Password").orderBy("company").onSnapshot((snap) => {
                    this.saveChunkSnapshot("0", snap);
                })
            } else {
                let i = 0;
                while (userGroup.length) {
                    const userGroupChunk = userGroup.splice(0, GROUP_CHUNK_SIZE);
                    if (userGroupChunk.length > 0) {
                        let index = String(i);
                        database.collection("Password")
                            .orderBy("company")
                            .where("permissionGroupIds", "array-contains-any", userGroupChunk).onSnapshot((snap) => {
                                this.saveChunkSnapshot(index, snap);
                            })
                    }
                    i++;
                }

                // get directly assigned password
                database
                    .collection("Password")
                    .orderBy("company")
                    .where("permissionUserIds", "array-contains", userStore.userId).onSnapshot((snap) => {
                        this.saveChunkSnapshot(String(i), snap);
                    });
            }


        },

        saveChunkSnapshot(index = "0", snap) {   
            this.allPasswordChunks[index] = snap.docs.map(doc => doc.data());
            console.log("Password: Saved", this.allPasswordChunks[index].length, "passwords to chunk", index, this.allPasswordChunks[index]);
            this.loading = false;
            this.updateAllPasswords();
        },

        updateAllPasswords() {
            let allPasswordsCombined = [];
            for (const [key, value] of Object.entries(this.allPasswordChunks)) {
                allPasswordsCombined = allPasswordsCombined.concat(value);
            }

            // chunks got combined. duplicates must be filtered and ordering must be restored
            if (Object.keys(this.allPasswordChunks).length > 1) {
                console.log("Sort", Object.keys(this.allPasswordChunks).length, "passwords chunks...");
                allPasswordsCombined = [...new Map(allPasswordsCombined.map(item => [item['id'], item])).values()];
                allPasswordsCombined.sort((a, b) => a.company.localeCompare(b.company));
            }

            this.allPasswords = allPasswordsCombined;
        },

        updatePw: function (pw) {
            var updatePw = fireFunctions.httpsCallable("updatePw");
            updatePw({
                id: pw.id,
                pw: pw.pw || "",
                company: pw.company || "",
                domain: pw.domain || "",
                mailLogin: pw.mailLogin || "",
                info: pw.info || "",
                permissionGroupIds: pw.permissionGroupIds || [""],
                permissionUserIds: pw.permissionUserIds || [""],
                private: pw.private,
                link: pw.link || "",
                twoFactorInfo: pw.twoFactorInfo || "",
                twoFactorBackupCodes: pw.twoFactorBackupCodes || "",
                otpEnabled: pw.otpEnabled || false,
                otpSecret: pw.otpSecret || "",
                description: pw.description,
            })
                .catch(function (error) {
                    console.error(error);
                })
                .finally(() => this.loading = false);
        },
    }
});