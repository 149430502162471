import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"650"},model:{value:(_vm.pwGeneratorDialogProp),callback:function ($$v) {_vm.pwGeneratorDialogProp=$$v},expression:"pwGeneratorDialogProp"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Password Generator ")]),_c(VCardText,[_c('PasswordGenerator',{attrs:{"generatedPassword":_vm.generatedPassword},on:{"update:generatedPassword":function($event){_vm.generatedPassword=$event},"update:generated-password":function($event){_vm.generatedPassword=$event}}})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Close ")]),(_vm.generatedPassword)?_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.copy}},[_vm._v(" Copy to form ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }