<template>
  <v-container id="settings-container">
    <v-row>
      <v-col cols="12" xl="6">
        <v-data-table :headers="headers" :items="permissionGroups" item-key="name" sort-by="name" class="elevation-1"
          dense>
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Permissions</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="isGroupAdmin" color="primary" dark class="mb-2" v-bind="attrs" v-on="on">New Group</v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="headline">New Group</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                        </v-col>
                        <v-col v-if="isNameUsed">
                          <p style="color: red">Name already in use!</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close, (dialog = false)">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="save(), (dialog = false)" v-if="!isNameUsed">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item="{ item }" v-if="allUser">
            <tr>
              <td colspan="4">
                <v-row no-gutters class="pt-2 pb-2">
                  <v-col cols="6" no-gutters><strong><v-chip small
                        :color="groupClassifications.find(e => e.value == item.category)?.color" outlined>{{ item.name }}
                      </v-chip>
                    </strong>
                  </v-col>

                  <v-col cols="4" no-gutters>
                    <v-chip v-if="isMyGroup(item)" small outlined class="mr-1"
                      @click="openGroupPasswordDialog(item.id, `${item.name}`)"
                      :disabled="getGroupPasswords(item.id, true).length == 0">
                      <v-icon small class="pr-1">mdi-key</v-icon> {{ getGroupPasswords(item.id, true).length }}
                    </v-chip>
                    <v-chip v-if="isMyGroup(item)" small outlined class="mr-1" color="blue lighten-2"
                      title="I am member of this group">
                      <v-icon small>mdi-account-details</v-icon>
                    </v-chip>
                    <v-chip v-if="isEditor(item)" small outlined class="mr-1" color="blue darken-3"
                      title="I am editor of this group">
                      <v-icon small>mdi-playlist-edit</v-icon>
                    </v-chip>
                  </v-col>
                  <v-col cols="2" no-gutters align="right">
                    <v-chip v-if="isEditor(item)" small icon @click="
                      (isOpenEditGroupDialog = true), (updateItem = item), makeCopyItem(item)
                      " class="mr-1"><v-icon small> mdi-pencil </v-icon></v-chip>
                    <v-chip v-if="isEditor(item)"
                      :disabled="item.memberIds.length != 1 || item.memberIds[0] != userId || getGroupPasswords(item.id, true).length > 0"
                      small icon @click="openDeleteDialog(item)" class="mr-1"><v-icon small> mdi-delete
                      </v-icon></v-chip>

                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="mb-1" no-gutters>
                  <v-col cols="6" no-gutters>
                    <v-autocomplete vi-if="allUser" style="max-height: 200px" small-chips deletable-chips multiple counter
                      :disabled="!isEnabled(item)" :items="allUser" item-text="email" item-value="id"
                      v-model="item.memberIds" @change="updatePermissionGroup(item)" />
                  </v-col><v-col cols="6" no-gutters>
                    <v-autocomplete vi-if="allUser" style="max-height: 200px" small-chips deletable-chips multiple
                      :disabled="!isEnabled(item)" counter :items="allUser" item-text="email" item-value="id"
                      v-model="item.editorIds" @change="updatePermissionGroup(item)" />
                  </v-col>
                </v-row>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" xl="6" v-if="isAdmin || isAdminSecurity">
        <v-card>
          <!--<v-text-field label="New Mail (Enter)" v-on:keyup.enter="addPersonToList" v-model="newMail"
            class="ma-2"></v-text-field>-->
          <v-list flat v-if="allUser">
            <v-subheader>
              <v-row>
                <v-col cols="4"> User </v-col>
                <v-col cols="1" class="pr-0"> Group Creator </v-col>
                <v-col cols="1"> Super Admin </v-col>
                <v-col cols="1"> Sec. Admin </v-col>
                <v-col cols="2"> Leave date</v-col>
                <v-col cols="3"> Actions</v-col>
              </v-row>
            </v-subheader>

            <v-list-item v-for="(user, i) in allUser" :key="i">
              <v-row align="start">
                <v-col cols="4" class="pt-0 pb-0">
                  <div class="body-2"
                    v-text="user.email + (user.previousEmails && user.previousEmails.length > 0 ? ` (${user.previousEmails.length})` : '')"
                    :title="user.email + (user.previousEmails && user.previousEmails.length > 0 ? ' - ' + user.previousEmails.join(', ') : '')">
                  </div>
                  <div class="caption grey--text" v-if="user.lastLogin"
                    v-text="'Last login: ' + formatFirebaseDate(user.lastLogin)"></div>
                </v-col>
                <v-col cols="1" class="d-flex justify-left align-center pl-5 pt-0 pb-0">
                  <v-icon @click="changeValueOfAdminGroup(user)" :disabled="!isAdmin">{{
                    user.adminGroup
                    ? "mdi-checkbox-marked-outline"
                    : "mdi-checkbox-blank-outline"
                  }}</v-icon></v-col>
                <v-col cols="1" class="d-flex justify-left align-center pl-5 pt-0 pb-0">
                  <v-icon @click="changeValueOfAdmin(user)" :disabled="!isAdmin">{{
                    user.admin
                    ? "mdi-checkbox-marked-outline"
                    : "mdi-checkbox-blank-outline"
                  }}</v-icon></v-col>
                  <v-col cols="1" class="d-flex justify-left align-center pl-5 pt-0 pb-0">
                  <v-icon @click="changeValueOfAdminSecurity(user)" :disabled="!isAdmin">{{
                    user.adminSecurity
                    ? "mdi-checkbox-marked-outline"
                    : "mdi-checkbox-blank-outline"
                  }}</v-icon></v-col>
                <v-col cols="2" class="pt-0 pb-0">
                  <v-chip v-if="user.leaveDate" small @click="openUserLeaveDatePicker(user)"
                    :color="user.leaveDate >= today ? 'orange' : 'red'" text-color="white">
                    <v-icon small class="mr-1">mdi-door</v-icon>{{ user.leaveDate }}
                  </v-chip>
                  <v-chip v-else small @click="openUserLeaveDatePicker(user)">
                    <v-icon small>mdi-clipboard-text-clock</v-icon>
                  </v-chip>
                </v-col>
                <v-col cols="3" class="pt-0 pb-0">
                  <v-chip small outlined title="Show log" class="mr-1" :to="'/LogTable?uid=' + user.id"><v-icon small
                      class="mr-1">mdi-text-search</v-icon></v-chip>
                  <v-chip small outlined @click="openUserPasswordDialog(user, `${user.email}`)"
                    :disabled="getUserPasswords(user).length == 0" class="mr-1"><v-icon small
                      class="mr-1">mdi-key</v-icon> {{ getUserPasswords(user).length }}</v-chip>
                  <v-chip small @click="removePersonFromList(user)" :disabled="!isAdmin"><v-icon
                      small>mdi-delete</v-icon></v-chip>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" v-if="isAdmin" no-gutters class="password-table">
        <v-card>
          <ComponentPwTable :items="archivedPWs" titleTable="Archived PWs" />
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" v-if="isAdmin && orphans.length > 0" no-gutters class="password-table">
        <v-card>
          <ComponentPwTable :items="orphans" titleTable="Passwords without groups" />
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" v-if="isAdmin && allPasswords.length > 0" no-gutters class="password-table">
        <v-card>
          <ComponentPwTable :items="allPasswords" titleTable="All Passwords" />
        </v-card>
      </v-col>
    </v-row>

    <!-- --------------- -->
    <v-dialog v-model="isOpenEditGroupDialog" max-width="300px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Group Name:</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field v-model="copyItem.name" label="Group Name"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col v-if="isUpdatedNameUsed">
                <p v-if="updateItem.name.trim() == copyItem.name.trim()" style="color: green">
                  This is the current group name!
                </p>
                <p v-else style="color: red">Name already in use!</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-select label="Group category" :items="groupClassifications" item-value="value"
                  v-model="copyItem.category" chips>
                  <template v-slot:item="data">
                    <span class="caption pr-1">{{ data.item.text }}</span>
                    <strong>
                      <v-chip small :color="data.item.color" outlined> {{ copyItem.name }} </v-chip>
                    </strong>
                  </template>
                  <template slot="selection" slot-scope="data">
                    <span class="caption">{{ data.item.text }}</span>
                    <strong>
                      <v-chip small :color="data.item.color" outlined> {{ copyItem.name }}</v-chip>
                    </strong>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="isOpenEditGroupDialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text v-if="!isUpdatedNameUsed"
            @click="(isOpenEditGroupDialog = false), saveName()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isOpenDeleteGroupDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Group</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                Do you really want to delete group <strong>{{ copyItem.name }}</strong>?
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="isOpenDeleteGroupDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="(isOpenDeleteGroupDialog = false), deleteGroup()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="passwordListDialog.open" max-width="600px">
      <v-card>
        <v-card-title>
          <div>Passwords of <u>{{ passwordListDialog.head }}</u></div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Company
                    </th>
                    <th class="text-left">
                      Info
                    </th>
                    <th class="text-left">
                      Login
                    </th>
                    <th class="text-left">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="pw in passwordListDialog.list" :key="pw.id">
                    <td class="text-left">
                      {{ pw.company }}
                    </td>
                    <td class="text-left">
                      {{ pw.info }}
                    </td>
                    <td class="text-left">
                      {{ pw.mailLogin }}
                    </td>
                    <td class="text-left">
                      <PopupEditPassword :currentPassword="pw" />
                    </td>
                  </tr>
                </tbody>
              </template></v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="passwordListDialog.open = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="leaveDateDialog.open" max-width="500px">
      <v-card>
        <v-card-title>
          <div>Set leave date</div>
        </v-card-title>
        <v-card-text>
          Set users leave date to mark passwords that were last changed <u>before</u> the leave.
          <v-divider class="mt-1 mb-2"></v-divider>
          <v-date-picker v-model="leaveDateDialog.date" landscape light show-current></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn color="gray" left text @click="leaveDateDialog.open = false">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue" right text
            @click="setUserLeaveDate(leaveDateDialog.user, null), leaveDateDialog.open = false"
            :disabled="leaveDateDialog.date == null">Remove date</v-btn>
          <v-btn color="blue" right text
            @click="setUserLeaveDate(leaveDateDialog.user, leaveDateDialog.date), leaveDateDialog.open = false">Set
            date</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-snackbar :timeout="timeout" v-model="snackbar">
        {{ snackbarText }}
        <v-btn color="info" @click.native="snackbar = false">Close</v-btn>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { usePermissionGroupStore } from "../store/PermissionGroup";
import { usePasswordStore } from "../store/Password";
import { useUserStore } from "../store/User";

import ComponentPwTable from "./ComponentPwTable";
import PopupEditPassword from "./popups/PopupEditPassword";
import moment from "moment"

export default {
  components: {
    PopupEditPassword,
    ComponentPwTable,
  },
  data() {
    return {
      today: new Date().toISOString().substring(0, 10),
      newMail: "",
      copyItem: {
        name: "",
      },
      updateItem: {
        name: "",
      },
      isOpenEditGroupDialog: false,
      isOpenDeleteGroupDialog: false,
      passwordListDialog: {
        list: [],
        head: "",
        open: false
      }, // password list in dialog
      leaveDateDialog: {
        open: false,
        user: null,
        date: null,
      },
      dialog: null,
      selected: [],
      headers: [
        {
          text: "Group",
          align: "start",
          value: "name",
          groupable: false,
        },
        {
          text: "Members",
          align: "start",
          value: "memberIds",
          groupable: false,
        },
        {
          text: "Editors",
          align: "start",
          value: "editorIds",
          groupable: false,
        },
      ],
      headersUser: [
        {
          text: "Editors",
          align: "start",
          value: "editorIds",
          groupable: false,
          width: 200,
        },
      ],
      editedItem: {
        name: "",
      },
      test: true,
      snackbarText: "",
      snackbar: false,
      timeout: 2000,
    };
  },
  computed: {
    ...mapState(usePermissionGroupStore, ["permissionGroups", "groupClassifications"]),
    ...mapState(usePasswordStore, ["groupPWs", "archivedPWs", "orphans", "allPasswords"]),
    ...mapState(useUserStore, ["isAdmin", "isGroupAdmin", "userId", "currentUserDb", "allUser"]),
    isNameUsed() {
      return !!this.permissionGroups.find(
        (pG) => pG.name === this.editedItem.name
      );
    },
    isUpdatedNameUsed() {
      return !!this.permissionGroups.find(
        (pG) => pG.name.trim() === this.copyItem.name.trim() && this.updateItem.name.trim() !== this.copyItem.name.trim()
      );
    },
    isAdmin() {
      return !!this.currentUserDb.admin;
    },
    isAdminSecurity() {
      return !!this.currentUserDb.admin || !!this.currentUserDb.adminSecurity;
    },
    isAdminGroup() {
      return !!this.currentUserDb.admin || !!this.currentUserDb.adminGroup;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    showSnackbar(text) {
      this.snackbarText = text;
      this.snackbar = true;
    },
    isEnabled(item) {
      return (
        this.currentUserDb.admin ||
        item.editorIds.includes(this.currentUserDb.id) ||
        item.editorIds.length === 0
      );
    },
    initialize() {
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      console.log("CreateNewPermissionGroup", this.editedItem.name);
      usePermissionGroupStore().createPermissionGroup(this.editedItem);
     },

    // -----

    makeCopyItem(item) {
      this.copyItem = structuredClone(item);
    },

    saveName() {
      usePermissionGroupStore().updatePermissionGroup(this.copyItem);
    },

    getGroupPasswords(groupId, includeArchived = false) {
      return this.groupPWs([groupId], includeArchived);
    },

    getUserPasswords(user) {
      if (user.groupIds) {
        return this.groupPWs(user.groupIds);
      }

      return [];
    },

    async openDeleteDialog(item) {
      this.updateItem = item;
      this.makeCopyItem(item)
      this.isOpenDeleteGroupDialog = true;
    },

    async deleteGroup() {
      let result = usePermissionGroupStore().deleteGroup(this.copyItem);
      if (result && result.error) {
        this.showSnackbar(result.error);
      } else {
        this.showSnackbar("Group " + this.copyItem.name + " deleted");
      }
    },

    async openGroupPasswordDialog(groupId, head) {
      this.passwordListDialog.head = head;
      this.passwordListDialog.list = this.getGroupPasswords(groupId, true)
      this.passwordListDialog.open = true;
    },

    async openUserPasswordDialog(user, head) {
      this.passwordListDialog.head = head;
      this.passwordListDialog.list = this.getUserPasswords(user)
      this.passwordListDialog.open = true;
    },

    openUserLeaveDatePicker(user) {
      this.leaveDateDialog.user = user;
      this.leaveDateDialog.open = true;
      this.leaveDateDialog.date = user.leaveDate || new Date().toISOString().substr(0, 10);
    },

    isEditor(permissionGroupItem) {
      let editor = !!permissionGroupItem.editorIds.find(
        (item) => item === this.currentUserDb.id
      );
      return editor || !!this.currentUserDb.admin;
    },

    /**
     * check if I am in this group
     * 
     * @param {*} group 
     */
    isMyGroup(group) {
      return group.memberIds.includes(this.currentUserDb.id);
    },

    // -----
    //eslint-disable-next-line
    updatePermissionGroup(item) {
      //eslint-disable-next-line
      // debugger;
      // console.log(item);
      usePermissionGroupStore().updatePermissionGroup(item);
    },
    async removePersonFromList(user) {
      usePermissionGroupStore().deleteUser(user);
      useUserStore().deleteUser(user);
    },
    async changeValueOfAdmin(user) {
      useUserStore().changeValueOfAdmin(user);
    },
    async changeValueOfAdminSecurity(user) {
      useUserStore().changeValueOfAdminSecurity(user);
    },
    async changeValueOfAdminGroup(user) {
      useUserStore().changeValueOfAdminGroup(user);
    },
    setUserLeaveDate(user, date) {
      user.leaveDate = date;
      useUserStore().setLeaveDate(user);
    },
    formatFirebaseDate(fbDate) {
      return moment(new Date(fbDate.seconds * 1000)).format('YYYY-MM-DD')
    },
  },
};
</script>
<style lang="scss">
#settings-container {
  .v-autocomplete {
    .v-select__selections {
      max-width: 500px;
      max-height: 160px;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
    }
  }

  .v-list {
    .v-list-item {
      max-height: 32px;
    }
  }

  .password-table {
    .v-card {
      min-height: 570px;
    }
  }

}
</style>
