<template>
    <v-dialog v-model="otpCaptureDialogProp" persistent max-width="650">
        <v-card>
            <v-card-title class="headline">
                Register Authenticator OTP Code
            </v-card-title>
            <v-card-text>
                <v-row v-if="!detectionFinished">
                    <v-col>
                        <h4>Drag&Drop QR Code image</h4>
                        <qrcode-drop-zone @detect="onDetect">
                            <div class="qrcode-drop">
                                <v-icon>mdi-upload-circle-outline</v-icon>Drop QR Code here
                            </div>
                        </qrcode-drop-zone>
                        <br><br>
                        <h4>Upload Code image</h4>
                        <div class="qrcode-capture">
                            <qrcode-capture @detect="onDetect"></qrcode-capture>
                        </div>
                    </v-col>
                    <v-col>
                        <h4>Scan QR Code with camera</h4>
                        <div class="qrcode-stream text-center" v-if="!streamEnabled" @click="enableStream">
                            <div class="ma-3 mt-5 caption">Directly scan the initial QR code or the export QR code of
                                Google Authenticator with your camera.</div>
                            <div class="mt-5">Click to start camera</div>
                        </div>
                        <div class="qrcode-stream" v-if="streamEnabled">
                            <qrcode-stream @detect="onDetect"></qrcode-stream>
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="detectionFinished">
                    <v-col class="mt-5 mb-5">
                        <h3>Detection was successfull</h3>
                    </v-col>
                </v-row>
                <v-row v-if="detectionError">
                    <v-col>
                        <v-alert small color="orange" dense dismissible outlined text type="warning">{{ detectionError
                            }}</v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn color="green darken-1" text @click="close">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import protobuf from 'protobufjs';
import base32 from 'hi-base32'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
export default {

    data() {
        return {
            detectionFinished: false,
            streamEnabled: false,
            detectionError: null,
            proto: `syntax = "proto3";

message MigrationPayload {
  enum Algorithm {
    ALGORITHM_UNSPECIFIED = 0;
    ALGORITHM_SHA1 = 1;
    ALGORITHM_SHA256 = 2;
    ALGORITHM_SHA512 = 3;
    ALGORITHM_MD5 = 4;
  }

  enum DigitCount {
    DIGIT_COUNT_UNSPECIFIED = 0;
    DIGIT_COUNT_SIX = 1;
    DIGIT_COUNT_EIGHT = 2;
  }

  enum OtpType {
    OTP_TYPE_UNSPECIFIED = 0;
    OTP_TYPE_HOTP = 1;
    OTP_TYPE_TOTP = 2;
  }

  message OtpParameters {
    bytes secret = 1;
    string name = 2;
    string issuer = 3;
    Algorithm algorithm = 4;
    DigitCount digits = 5;
    OtpType type = 6;
    int64 counter = 7;
  }

  repeated OtpParameters otp_parameters = 1;
  int32 version = 2;
  int32 batch_size = 3;
  int32 batch_index = 4;
  int32 batch_id = 5;
}`,
        }
    },
    components: {
        QrcodeStream,
        QrcodeDropZone,
        QrcodeCapture,
    },
    name: "PopupOtpQr",
    props: {
        otpSecretProp: {
            default: "",
        },
        otpEnabledProp: {
            default: false,
        },
        otpCaptureDialogProp: {
            default: false,
        },
    },
    methods: {
        /**
         * catch detect event from qr component
         * 
         * @param detectedCodes 
         */
        async onDetect(detectedCodes) {
            this.detectionError = null; // reset error message
            let detected = await detectedCodes;
            let secret = null;

            console.debug("Detected QR content", detected);
            if (!detected?.content) {
                this.detectionError = "File contains no QR code.";
            }

            // default initialization QR Code
            if (detected?.content.startsWith('otpauth://')) {
                let url = new URL(detected.content);
                secret = url.searchParams.get('secret').toUpperCase();
                // authenticator export QR Code
            } else if (detected?.content.startsWith('otpauth-migration://')) {
                let url = new URL(detected.content);
                secret = this.getSecretFromProtoBase64(url.searchParams.get('data'));
            } else {
                this.detectionError = "QR Code detected, but content seems invalid.";
            }

            if (secret && secret.length >= 16) {
                this.detectionFinished = true;
                console.log("OTP Secret detected", secret);
                this.$emit("update:otpSecretProp", secret);
                this.$emit("update:otpEnabledProp", true);
                let that = this;
                setTimeout(function () {
                    that.close();
                }, 2000);
            }
        },
        /**
         * get the otp secret out of an otpauth-migration string
         * 
         * @param b64data 
         * 
         */
        getSecretFromProtoBase64(b64data) {
            const protoBinary = Uint8Array.from(atob(b64data), c => c.charCodeAt(0))
            const protoBuffer = protobuf.parse(this.proto);
            const MigrationPayload = protoBuffer.root.lookupType("MigrationPayload");
            let err = MigrationPayload.verify(protoBinary);
            if (err) {
                console.error(err);
                throw err;
            }
            const message = MigrationPayload.decode(protoBinary);
            const obj = MigrationPayload.toObject(message);

            if (!obj?.otpParameters?.[0]?.secret) {
                err = "Could not find Secret in OTP Migration Code";
                console.error(err);
                throw (err);
            }

            let secret = base32.encode(obj.otpParameters[0].secret);

            return secret;
        },
        /**
         * enable camera stream capturing mode
         */
        enableStream() {
            this.streamEnabled = true;
        },
        /**
         * close popup
         */
        close() {
            // this.otpCaptureDialogProp = false;
            this.$emit("update:otpCaptureDialogProp", false);
        }
    }
}

</script>
<style lang="scss" scoped>
h4 {
    padding-bottom: 4px;
}

.qrcode-capture {
    overflow: hidden;
}

.qrcode-stream {
    background: rgb(226, 226, 226);
    background: linear-gradient(135deg, rgba(226, 226, 226, 1) 12%, rgba(238, 238, 238, 1) 37%);
    border: 1px solid gray;
    height: 200px;
    cursor: pointer;
}

.qrcode-drop {
    border: 1px solid gray;
    height: 60px;
    text-align: center;
    padding-top: 18px;
}
</style>