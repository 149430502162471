import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"650"},model:{value:(_vm.otpCaptureDialogProp),callback:function ($$v) {_vm.otpCaptureDialogProp=$$v},expression:"otpCaptureDialogProp"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Register Authenticator OTP Code ")]),_c(VCardText,[(!_vm.detectionFinished)?_c(VRow,[_c(VCol,[_c('h4',[_vm._v("Drag&Drop QR Code image")]),_c('qrcode-drop-zone',{on:{"detect":_vm.onDetect}},[_c('div',{staticClass:"qrcode-drop"},[_c(VIcon,[_vm._v("mdi-upload-circle-outline")]),_vm._v("Drop QR Code here ")],1)]),_c('br'),_c('br'),_c('h4',[_vm._v("Upload Code image")]),_c('div',{staticClass:"qrcode-capture"},[_c('qrcode-capture',{on:{"detect":_vm.onDetect}})],1)],1),_c(VCol,[_c('h4',[_vm._v("Scan QR Code with camera")]),(!_vm.streamEnabled)?_c('div',{staticClass:"qrcode-stream text-center",on:{"click":_vm.enableStream}},[_c('div',{staticClass:"ma-3 mt-5 caption"},[_vm._v("Directly scan the initial QR code or the export QR code of Google Authenticator with your camera.")]),_c('div',{staticClass:"mt-5"},[_vm._v("Click to start camera")])]):_vm._e(),(_vm.streamEnabled)?_c('div',{staticClass:"qrcode-stream"},[_c('qrcode-stream',{on:{"detect":_vm.onDetect}})],1):_vm._e()])],1):_vm._e(),(_vm.detectionFinished)?_c(VRow,[_c(VCol,{staticClass:"mt-5 mb-5"},[_c('h3',[_vm._v("Detection was successfull")])])],1):_vm._e(),(_vm.detectionError)?_c(VRow,[_c(VCol,[_c(VAlert,{attrs:{"small":"","color":"orange","dense":"","dismissible":"","outlined":"","text":"","type":"warning"}},[_vm._v(_vm._s(_vm.detectionError))])],1)],1):_vm._e()],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Close ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }