<template>
  <span v-if="isLoggedIn">
    <!-- headline -->
    <v-app-bar app clipped-left color="#fff" flat>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <span class="title ml-3 mr-5">
        <div class="float-left">
          PCG
          <span class="font-weight-light">Password Safe</span>
        </div>
        <div class="float-left" v-if="getStage() == 'dev'">
          <span class="red--text pl-4">Development Version</span>
          <span class="pl-4 caption">
            <a href="https://cwpwsafe.web.app/" target="_blank">(Go to Production Version <v-icon small>mdi-open-in-new</v-icon>)</a>
          </span>
        </div>
      </span>
      <v-spacer />
      <span class="ml-3 mr-3 d-none d-sm-flex">{{ userEmail }}</span>
      <v-avatar color="indigo" @click="housekeeping">
        <img v-if="!!thumbnailUrl" v-bind:src="thumbnailUrl" />
        <v-icon v-else dark>mdi-account-circle</v-icon>
      </v-avatar>
    </v-app-bar>

    <!-- menu -->
    <v-navigation-drawer v-model="drawer" app clipped color="grey lighten-4" disable-resize-watcher>
      <v-list>
        <template v-for="(item, i) in items">
          <template v-if="!item.adminOnly || isAdmin || isAdminSecurity">
            <!-- link item -->
            <v-list-item :to="item.link" v-if="item.link" :key="i">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- function item -->
            <v-list-item v-on:click="call(item.function)" v-if="item.function" :key="i">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
  </span>
</template>

<script>
import firebase from 'firebase/app';
import { mapState } from "pinia"
import { fireFunctions } from "../store/database";
import { useUserStore } from '../store/User';

export default {
  name: "AppNavigation",
  props: {
    source: String,
  },
  data: () => ({
    logo: "../public/logo_horizontal.svg",
    drawer: false,
    items: [
      { icon: "mdi-lock", text: "Passwords", link: "/PWTable" },
      { icon: "mdi-archive", text: "Logs", link: "/LogTable", adminOnly: true },
      { icon: "mdi-cogs", text: "Settings", link: "/Settings" },
      { icon: "mdi-logout", text: "Logout", function: "logout" },
    ],
  }),
  computed: {
    ...mapState(useUserStore, ["currentUserDb", "isAdmin", "isAdminSecurity", "isLoggedIn"]),
    userEmail() {
      return this.currentUserDb?.email || "";
    },
    thumbnailUrl() {
      return this.currentUserDb?.photoURL || "";
    },
  },
  methods: {
    housekeeping() {
      console.log("Run housekeeping...");
      var housekeeping = fireFunctions.httpsCallable("housekeeping");
      housekeeping()
        .then((r) => {
          console.log("Finished housekeeping", r);
        })
        .catch((e) => console.error(e));
    },
    logout() {
      firebase.auth().signOut();
      this.$router.push('auth/Login')
    },
    call(method, args = []) {
      this[method](...args)
    },
    getStage() {
      return firebase.app().options_.projectId == "cwpwsafe" ? "prod" : "dev";
    }
  },
};
</script>

<style>
#keep .v-navigation-drawer__border {
  display: none;
}
</style>
