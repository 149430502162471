import { defineStore } from 'pinia'
import { database } from './database';

/**
 * @todo Integrate Firestore Ref-Binding (Vuefire) on each action * 
 */
export const useLogStore = defineStore('LogStore', {
    state: () => {
        return {
            logs: [],
        }
    },
    getters: {
        /**
         * get formatted lists of logs from state
         * 
         * @param {*} state 
         * @returns 
         */
        formattedLogs: state => {
            if (!state.logs) return [];
            return state.logs.map(log => {
                let nLog = {
                    ...log
                };
                let date = new Date();
                date.setTime(nLog.time.seconds * 1000);
                nLog.time = date.toLocaleString("de-DE");
                return nLog;
            });
        }
    },
    actions: {
        /**
         * load list of logs to the state
         * 
         * @param {*} options 
         */
        bindLogs: function (options) {
            let dbQuery;

            // initialize filter to query
            if (options && options.field && options.value) {
                console.log("Log: Search logs by field", options.field, "with value", options.value);
                dbQuery = database
                    .collection("Log").where(options.field, "==", options.value);
            } else {
                console.log("Log: Load latest 500 Log entries");
                dbQuery = database
                    .collection("Log");
            }

            // run and bind query
            dbQuery.orderBy("time", "desc").limit(500).onSnapshot((snap) => {
                this.logs = snap.docs.map(doc => doc.data());
                console.log("Log: Found", this.logs.length, "logs");
            });
        },
        /**
         * get logs for a certain password
         * 
         * @param {*} passwordId 
         * @returns 
         */
        getPasswordLogs: function (passwordId) {
            return database
                .collection("Log")
                .where("password.id", "==", passwordId)
                //.where("action", "in", ["create", "read"])
                .orderBy("time", "desc")
                .get();
        },
    }

});