import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,[_c('h4',[_vm._v("Password")]),_c(VTextField,{attrs:{"label":"Generated password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VBtn,{staticClass:"mr-5",on:{"click":_vm.onGenerate}},[_vm._v("Generate")]),_c(VBtn,{attrs:{"disabled":this.password.length == 0},on:{"click":_vm.onCopyPass}},[_vm._v("Clipboard")])],1),_c(VCol,[_c('h4',[_vm._v("Settings")]),_c('br'),_c('div',{staticClass:"text-caption"},[_vm._v("Length")]),_c(VSlider,{attrs:{"min":"4","max":"16","thumb-label":"always","thumb-size":20},model:{value:(_vm.gLength),callback:function ($$v) {_vm.gLength=$$v},expression:"gLength"}}),_vm._l((_vm.characters),function(item){return _c(VCheckbox,{key:item.name,staticClass:"mt-0 mb-0",attrs:{"label":item.name,"input-value":item.value},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }