import { defineStore } from 'pinia'
import { database } from './database';
import { useUserStore } from './User';

/**
 * @todo Integrate Firestore Ref-Binding (Vuefire) on each action * 
 */
export const usePermissionGroupStore = defineStore('PermissionGroupStore', {
    state: () => {
        return {
            permissionGroups: [],
        }
    },
    getters: {
        groupClassifications(state) {
            return [
                { value: "general", text: "General", color: "green" },
                { value: "local", text: "Local", color: "teal" },
                { value: "scoped", text: "Scoped", color: "purple" },
                { value: "confidential", text: "Confidential", color: "orange" },
                { value: "restricted", text: "Restricted", color: "red" },
            ]
        }
    },
    actions: {
        bindPermissionGroups: async function () {
            database.collection("PermissionGroup").orderBy("name").onSnapshot((snap) => {
                this.permissionGroups = snap.docs.map(doc => doc.data());
                console.debug("PermissionGroups: Found", this.permissionGroups.length, "groups");
            });
        },
        createPermissionGroup: function (newPermissionGroup) {
            // return the promise so we can await the write
            const pgRef = database.collection("PermissionGroup").doc();
            const userId = useUserStore().userId;
            const res = pgRef.set({
                name: newPermissionGroup.name,
                memberIds: [userId],
                editorIds: [userId],
                id: pgRef.id,
            });
            return res;
        },
        updatePermissionGroup(updatedPermissionGroup) {
            // //eslint-disable-next-line
            let result = database
                .collection("PermissionGroup")
                .doc(updatedPermissionGroup.id)
                .update(updatedPermissionGroup);
        },
        deleteGroup: async function (deleteGroup) {
            console.log("PermissionGroups: Delete group", deleteGroup.id);
            let pws = await database.collection("Password").where("permissionGroupIds", "array-contains", deleteGroup.id).get();
            if (pws.docs.length > 0) {
                return { "error": "Group " + deleteGroup.name + " is still assigned to " + pws.docs.length + " passwords" };
            }

            let result = database
                .collection("PermissionGroup")
                .doc(deleteGroup.id)
                .delete();
        },
        deleteUser: async function (user) {
            const snapshot = await database.collection('PermissionGroup').get();

            snapshot.forEach(doc => {
                database.collection('PermissionGroup').doc(doc.id).update({
                    memberIds: firebase.firestore.FieldValue.arrayRemove(user.id),
                    editorIds: firebase.firestore.FieldValue.arrayRemove(user.id),
                })
            });
        }
    }
})