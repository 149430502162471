<template>
  <v-dialog v-model="dialog" persistent max-width="800px" @keydown.esc="dialog = false">
    <!-- list integration -->
    <template #activator="{ on: dialog }">
      <v-tooltip top v-if="isEditing">
        <template #activator="{ on: tooltip }">
          <v-btn v-show="!goneUserWithKnowledge.length" small icon v-on="{ ...tooltip, ...dialog }">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn v-show="goneUserWithKnowledge.length" small icon v-on="{ ...tooltip, ...dialog }">
            <v-icon color="red" text-color="white" small>mdi-alert</v-icon>
          </v-btn>
        </template>
        <span v-if="!goneUserWithKnowledge.length"> Edit / Show </span>
        <span v-if="goneUserWithKnowledge.length">{{ goneUserWithKnowledge.length }} gone users has knowledge of the
          current password.
          Changing the password is
          recommended.</span>
      </v-tooltip>
      <v-tooltip top v-if="!isEditing">
        <template #activator="{ on: tooltip }">
          <v-btn color="primary" dark v-on="{ ...tooltip, ...dialog }" fab small>
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </template>
        <span> Create </span>
      </v-tooltip>
    </template>

    <!-- popup content -->
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span v-if="isEditing" class="headline">Edit Password</span>
          <span v-else class="headline">Create Password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Login *" v-model="password.mailLogin" :rules="ruleRequired" required autocomplete="off"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Password *" v-model="password.pw" :type="showPw ? 'text' : 'password'"
                  :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'" autocomplete="new-password">
                  <template slot="append">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="openPwGenerator" class="mr-3" title="Generate new password" v-bind="attrs"
                          v-on="on">mdi-lock-plus-outline</v-icon>
                      </template>
                      <span>Password Generator</span>
                    </v-tooltip><v-tooltip bottom v-if="!showPw">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="showPw = !showPw" title="Show password" v-bind="attrs" v-on="on">mdi-eye</v-icon>
                      </template>
                      <span>Show password</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="showPw">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="showPw = !showPw" v-if="showPw" title="Hide password" v-bind="attrs"
                          v-on="on">mdi-eye-off</v-icon>
                      </template>
                      <span>Hide password</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
                <password-meter :password="password.pw" />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Domain" v-model="password.domain"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Info" v-model="password.info"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="password.company" label="Company"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="password.link" label="Link"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12"
                v-if="!isEditing || password.private == userId || password.createdById == userId || passwordCreated.createdBy?.split('@')[0] == userEmail.split('@')[0]"
                class="privateSelector">
                <v-checkbox v-model="password.private" :value="userId"
                  :disabled="!password.private && password.permissionGroupIds && password.permissionGroupIds.length > 0"
                  :label="'My Private Password'" title="Private passwords are visible to admins!"
                  @click="setVisibility"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="isEditing && !!password.private && password.private != userId"
                no-gutters dense class="privateSelector">
                Private password of user {{ getUserById(password.private) }}
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-0" v-if="!password.private">
                <v-autocomplete :items="allPermissionGroupsMine" v-model="password.permissionGroupIds" item-text="name"
                  item-value="id" label="Permission Groups *" multiple :rules="ruleArrRequired" required>
                  <template #selection="{ item }">
                    <v-chip :disabled="item.disabled" :color="groupClassifications.find(e => e.value == item.category)?.color" close small
                      @click:close="deleteGroupChip(item.id)" outlined>{{ item.name }}</v-chip>
                  </template>
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :disabled="item.disabled"
                          :color="groupClassifications.find(e => e.value == item.category)?.color"
                          :input-value="active"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip :disabled="item.disabled" dense small :color="groupClassifications.find(e => e.value == item.category)?.color" outlined> {{
                            item.name }} </v-chip>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-0" v-if="allUser">
                <v-autocomplete :items="allUser" v-model="password.permissionUserIds" item-text="email" item-value="id" label="Inidividual user permissions" multiple deletable-chips small-chips>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pa-0" v-if="passwordUsers.length > 1">
                <v-list outlined small class="pa-0">
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-title>
                        <v-chip small outlined class="mr-1"><v-icon small class="pr-1"
                            title="Total users with access">mdi-account</v-icon>{{ passwordUsers.length }}</v-chip>
                        <v-chip small outlined class="mr-1" color="orange" title="User with future leave"
                          v-if="passwordUsersWithLeaveFuture.length"><v-icon small class="pr-1">mdi-door</v-icon>{{
                            passwordUsersWithLeaveFuture.length }}</v-chip>
                        <v-chip small outlined class="mr-1" color="red" title="Users that have left"
                          v-if="passwordUsersWithLeavePast.length"><v-icon small class="pr-1">mdi-door</v-icon>{{
                            passwordUsersWithLeavePast.length }}</v-chip>
                        <v-tooltip top v-if="goneUserWithKnowledge.length">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-1" color="red" text-color="white" v-on="on"
                              v-bind="attrs">mdi-alert</v-icon>
                          </template>
                          <span>{{ goneUserWithKnowledge.length }} gone users has knowledge of the current password.
                            Changing the password is
                            recommended.</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </template>
                    <v-list-item dense v-for="u in passwordUsers" :key="u.id" :set="lastAccess = getPasswordLastAccess(u)"
                      :class="u.inGroups.length > 0 || password.permissionUserIds?.includes(u.id) === true ? '' : 'not-in-groups'">
                      {{ u.email }}
                      <v-chip v-if="u.inGroups.length == 0 && password.permissionUserIds?.includes(u.id) !== true" small class="ml-2"
                        title="Had access, not permmitted anymore"><v-icon small>mdi-eye-off</v-icon></v-chip>
                      <v-chip v-if="password.permissionUserIds?.includes(u.id) === true" small outlined class="ml-2"
                        title="Has direct access"><v-icon small>mdi-arrow-bottom-left-bold-box-outline</v-icon></v-chip>
                      <v-chip v-if="u.inGroups.length > 0" small outlined
                        :title="'Access by groups: ' + u.inGroups.map(g => getGroupName(g)).join(', ')"
                        class="ml-2"><v-icon small class="pr-1">mdi-account-group</v-icon> {{ u.inGroups.length
                        }}</v-chip>
                      <v-chip v-if="lastAccess" small outlined class="ml-2" title="Last accessed"><v-icon small
                          class="pr-1">mdi-eye</v-icon>{{ lastAccess }}</v-chip>
                      <v-chip v-if="u.leaveDate" :color="u.leaveDate >= today ? 'orange' : 'red'" small outlined
                        title="User leave date" class="ml-2"><v-icon small>mdi-door</v-icon>{{
                          u.leaveDate }}</v-chip>
                      <v-tooltip top
                        v-if="passwordUpdated && u.leaveDate < today && (passwordUpdated.updatedBy == u.email || lastAccess > passwordUpdated.updated)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ml-2" color="red" text-color="white" v-on="on" v-bind="attrs">mdi-alert</v-icon>
                        </template>
                        <span v-if="passwordUpdated.updatedBy == u.email">
                          User {{ u.email }} is the creator or last editor of this password.<br>Changing the password is
                          recommended.
                        </span>
                        <span v-else>User {{ u.email }} accessed the most recent password.<br>
                          Password created/last changed: {{ passwordUpdated.updated }} by {{
                            passwordUpdated.updatedBy }}<br>
                          Last access: {{ lastAccess }}.<br>Changing the password is
                          recommended.</span>
                      </v-tooltip>
                      <v-tooltip top
                        v-if="passwordUpdated && lastAccess <= passwordUpdated.updated && u.leaveDate < today">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ml-2" color="blue" text-color="white" v-on="on"
                            v-bind="attrs">mdi-information</v-icon>
                        </template>
                        <span>The user had access to a previous version of the password.</span>
                      </v-tooltip>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="mb-0 mt-1">
                <PopupOtpQr :otpSecretProp.sync="password.otpSecret" :otpEnabledProp.sync="password.otpEnabled" :otpCaptureDialogProp.sync="otpCaptureDialog">
                </PopupOtpQr>
                <div v-if="password.otpEnabled">
                    <v-icon>mdi-checkbox-marked-circle-outline</v-icon> Authenticator OTP is enabled
                </div>
                <div v-if="!password.otpEnabled">
                  <v-icon>mdi-checkbox-blank-circle-outline</v-icon> Authenticator OTP is not enabled
                </div>               
              </v-col>
              <v-col cols="6" sm="6" md="6" class="mb-0 mt-1">
                <div v-if="!password.otpSecret && !otpChanged">
                  <v-btn small @click="openOtpCapture">Register QR Code</v-btn>
                  <v-btn small @click="disableOtp" class="ml-2" v-if="password.otpEnabled">Disable OTP</v-btn>
                </div>
                <div v-else>
                  <v-icon>mdi-content-save-move-outline</v-icon> Configuration changed. Save to persist.
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-textarea name="textareaBackupCodes" v-model="password.twoFactorBackupCodes"
                  label="Backup Codes"></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-textarea v-model="password.twoFactorInfo" label="Two Factor Info > Keys etc."></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="password.description" label="Description"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <small>*indicates required field</small>
              </v-col>
            </v-row>
            <v-row class="grey lighten-5">
              <v-col cols="6" class="text-caption" v-if="passwordCreated">
                Password created by:
                <br> {{ passwordCreated.created }}, {{ passwordCreated.createdBy }}
              </v-col>
              <v-col cols="6" class="text-caption" v-if="passwordUpdated">
                Password last edited by:
                <br>{{ passwordUpdated.updated }}, {{ passwordUpdated.updatedBy }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <PopupPasswordGenerator :pwGeneratorDialogProp.sync="pwGeneratorDialog"
            :currentPasswordProp.sync="password.pw" />
          <v-tooltip top v-if="(isAdmin || isEditor || password.createdById == userId) && !password.archived">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="blue darken-1" icon @click="archivePassword(true)" v-if="password.id" v-on="on"
                v-bind="attrs">
                <v-icon> mdi-archive-arrow-down </v-icon>
              </v-btn>
            </template>
            <span> Archive </span>
          </v-tooltip>
          <v-tooltip top v-if="(isAdmin || isEditor) && password.archived">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="blue darken-1" icon @click="archivePassword(false)" v-if="password.id" v-on="on"
                v-bind="attrs">
                <v-icon> mdi-archive-arrow-up </v-icon>
              </v-btn>
            </template>
            <span> Restore </span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Close</v-btn>
          <v-btn color="blue darken-1" text @click="updateCreatePw" :disabled="!valid">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "pinia";
import { usePermissionGroupStore } from "../../store/PermissionGroup";
import { usePasswordStore } from "../../store/Password";
import { useLogStore } from "../../store/Log";
import { useUserStore } from "../../store/User";

import passwordMeter from "vue-simple-password-meter";
import PopupPasswordGenerator from "./PopupPasswordGenerator";
import PopupOtpQr from "./PopupOtpQr.vue";
import moment from "moment"

export default {
  data() {
    return {
      today: new Date().toISOString().substring(0, 10),
      showPw: false,
      passwordObj: {
        mailLogin: "",
        pw: "",
        domain: "",
        info: "",
        company: "",
        permissionGroupIds: [],
        permissionUserIds: [],
        link: "",
        twoFactorInfo: "",
        twoFactorBackupCodes: "",
        otpEnabled: false,
        otpSecret: "",
        description: "",
        private: false,
      },
      valid: true,
      dialog: false,
      isEditing: !!this.currentPassword,
      password: this.currentPassword
        ? structuredClone(this.currentPassword)
        : { ...this.passwordObj },
      passwordLog: [],
      pwGeneratorDialog: false,
      otpCaptureDialog: false,
      otpChanged: false,
      ruleRequired: [(v) => !!v || "Required"],
      ruleArrRequired: [(v) => (!!v && v.length > 0) || "Required"],
    };
  },
  components: {
    PopupPasswordGenerator,
    PopupOtpQr,
    passwordMeter,
  },
  name: "PopupEditpassword",
  props: ["currentPassword"],
  computed: {
    // pinia mappings --- 
    ...mapState(usePermissionGroupStore, {
      allPermissionGroups: (state) => state.permissionGroups,
    }),
    ...mapState(usePermissionGroupStore, ["groupClassifications"]),
    ...mapState(useUserStore, ["isAdmin", "isAdminSecurity", "userId", "userEmail", "userGroups", "allUser"]),
    /*
     * get list of groups that have the non-mine groups disabled
     */
    allPermissionGroupsMine() {
      const myGroups = [{ "header": "Your groups" }];
      const notMyGroups = [{ "divider": true }, { "header": "Not your groups" }];

      for (var key in this.allPermissionGroups) {
        delete this.allPermissionGroups[key].passwords; // property is not cloneable
        var element = structuredClone(this.allPermissionGroups[key]);
        if (this.userGroups.includes(element.id)) {
          element.disabled = false;
          myGroups.push(element);
        } else {
          element.disabled = true;
          notMyGroups.push(element);
        }
      }

      return myGroups.concat(notMyGroups);
    },
    /*
     * user is included in assigned group
     */
    isEditor() {
      // password or group list is empty
      if (!this.currentPassword || this.currentPassword.permissionGroupIds == null) {
        return false;
      }

      let permissionGroups = this.allPermissionGroups.filter(
        (permissionGroup) =>
          Array.isArray(this.currentPassword.permissionGroupIds) && this.currentPassword.permissionGroupIds.includes(permissionGroup.id)
      );

      let isEditor = permissionGroups.some((permissionGroup) =>
        Array.isArray(permissionGroup.editorIds) && permissionGroup.editorIds.includes(this.userId)
      );

      return isEditor;
    },
    passwordCreated() {
      let data = { "created": "--", "createdBy": "--" }

      if (this.password.created) {
        data.created = moment(new Date(this.password.created.seconds * 1000)).format('YYYY-MM-DD');
        data.createdBy = this.password.createdById ? this.getUserById(this.password.createdById) : this.password.createdBy;
      } else {
        // get created date from log
        let logCreated = this.passwordLog.filter(el => el.action == 'created');
        if (logCreated.length > 0) {
          // at least one password creation was found in logs
          let logCreatedFirst = logCreated.pop()
          data.created = moment(new Date(logCreatedFirst.time.seconds * 1000)).format('YYYY-MM-DD');
          data.createdBy = logCreatedFirst.userId ? this.getUserById(logCreatedFirst.userId) : logCreatedFirst.user;
        }
      }

      return data;
    },
    passwordUpdated() {
      let data = { "updated": "--", "updatedBy": "--" }

      if (this.password.updated) {
        data.updated = moment(new Date(this.password.updated.seconds * 1000)).format('YYYY-MM-DD');
        data.updatedBy = this.password.updatedById ? this.getUserById(this.password.updatedById) : this.password.updatedBy;
      } else {
        // get last update form log
        let logUpdated = this.passwordLog.filter(el => el.action == 'password changed');
        if (logUpdated.length > 0) {
          // at least one password change was found in logs
          let logUpdatedLast = logUpdated.shift()
          data.updated = moment(new Date(logUpdatedLast.time.seconds * 1000)).format('YYYY-MM-DD');
          data.updatedBy = logUpdatedLast.userId ? this.getUserById(logUpdatedLast.userId) : logUpdatedLast.user;
        } else {
          // no updated entry and no log-entry. Use creation information instead
          let passwordCreated = this.passwordCreated;
          data.updated = passwordCreated.created;
          data.updatedBy = passwordCreated.createdBy;
        }
      }

      return data;
    },
    passwordUsersWithLeaveFuture() {
      return this.getPasswordUsersWithLeave(this.password, 'f');
    },
    passwordUsersWithLeavePast() {
      return this.getPasswordUsersWithLeave(this.password, 'p');
    },
    passwordUsers() {
      return this.getPasswordUsers(this.password).sort((a, b) => a.email.localeCompare(b.email));
    },
    goneUserWithKnowledge() {
      if (this.password && this.passwordUpdated && this.passwordUsersWithLeavePast) {
        return this.passwordUsersWithLeavePast.filter(u => {
          return this.passwordUpdated.updatedBy == u.id || this.passwordUpdated.updatedBy == u.email || this.getPasswordLastAccess(u) > this.passwordUpdated.updated
        });
      }

      return [];
    },
  },
  async mounted() {
    // load password history and logs
    if (this.password.id) {
      this.passwordLog = (await useLogStore().getPasswordLogs(this.password.id)).docs.map(doc => doc.data());
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    clearPw() {
      this.password = JSON.parse(JSON.stringify(this.passwordObj));
    },
    updateCreatePw() {
      if (this.validate()) {
        if (this.isEditing) {
          usePasswordStore().updatePw(this.password);
        } else {
          usePasswordStore().createNewPw(this.password);
        }
        if (!this.isEditing) {
          this.clearPw();
        }
        this.dialog = false;
      }
    },
    openPwGenerator() {
      this.pwGeneratorDialog = true;
    },
    openOtpCapture() {
      this.otpCaptureDialog = true;
    },
    disableOtp() {
      this.password.otpSecret = "";
      this.password.otpEnabled = false;
      this.otpChanged = true;
    },
    archivePassword(archived) {
      usePasswordStore().archivePw({
        id: this.password.id,
        archived: archived,
      });
      this.close();
    },
    getPasswordUsers() {
      let accessingUsers = Object.keys(this.getPasswordAllAccesses());
      var userList = {};
      if (!this.allUser) return userList;

      this.allUser.forEach(u => {
        let user = structuredClone(u);
        user.inGroups = this.getPasswordUsersAccessGroups(user);
        if (Array.isArray(this.password.permissionUserIds) && this.password.permissionUserIds.includes(user.id)) {
          // user is individually permitted
          userList[user.id] = user;
        } else if (user.inGroups.length > 0 || (this.password && (accessingUsers.includes(user.id) || accessingUsers.includes(user.email)))) {
          // user is in at least one group
          userList[user.id] = user;
        }
      });

      return Object.values(userList);
    },
    getPasswordUsersAccessGroups(user) {
      if (this.password && Array.isArray(this.password.permissionGroupIds)) {
        return this.password.permissionGroupIds.filter((g) => this.isUserInGroup(g, user.id))
      }
      return [];
    },
    getPasswordUsersWithLeave(pw, scope) {
      if (scope == 'f') {
        return this.getPasswordUsersWithLeave(pw, null).filter((p) => p.leaveDate && p.leaveDate >= this.today);
      } else if (scope == 'p') {
        return this.getPasswordUsersWithLeave(pw, null).filter((p) => p.leaveDate && p.leaveDate < this.today);
      }
      return this.getPasswordUsers(pw);
    },
    /*
     * get last access on this password by user
     */
    getPasswordAllAccesses() {
      let lastAccessesByUser = {};
      // get all relevant log entries and check user of each entry
      let lastAccesses = this.passwordLog.filter(l => (l.action == 'read' || l.action == 'created' || l.action == 'password changed'));
      lastAccesses.forEach(lastAccess => {
        /*
         * save last access for user id and for user email
         */
        if (lastAccess.userId) {
          if (!lastAccessesByUser[lastAccess.userId]) {
            lastAccessesByUser[lastAccess.userId] = moment(new Date(lastAccess.time.seconds * 1000)).format("YYYY-MM-DD HH:mm");
          }
        } else {
          // legacy log entries do not contain the userid
          if (!lastAccessesByUser[lastAccess.user]) {
            lastAccessesByUser[lastAccess.user] = moment(new Date(lastAccess.time.seconds * 1000)).format("YYYY-MM-DD HH:mm");
          }
        }
      });

      return lastAccessesByUser;
    },

    /*
     * get date of last access of user
     */
    getPasswordLastAccess(user) {
      let accessesByUser = this.getPasswordAllAccesses();
      // the user accessed the password
      if (accessesByUser[user.id]) {
        return accessesByUser[user.id]
      }

      // (legacy) the users current email adress accessed the password
      if (accessesByUser[user.email]) {
        return accessesByUser[user.email]
      }

      // (legacy) one previous email address of the user accessed the password
      if (user.previousEmails && user.previousEmails.length > 0) {
        let previousEmails = user.previousEmails.reverse();
        for (let pemail of previousEmails) {
          if (accessesByUser[pemail]) {
            return accessesByUser[pemail]
          }
        }
      }

      return null;
    },
    getUserById(id) {
      let user = this.allUser.find(u => u.id == id);

      return user ? user.email : "n/a";
    },
    isUserInGroup(groupId, userId) {
      if (this.allPermissionGroups) {
        let group = this.allPermissionGroups.filter(e => e.id == groupId && e.memberIds.includes(userId));
        if (group && group.length > 0) {
          return true;
        }
      }

      return false;
    },
    getGroupName(gId) {
      if (this.allPermissionGroups) {
        return this.allPermissionGroups.find(g => g.id == gId).name;
      }

      return null;
    },
    setVisibility() {
      if (!this.password.private) {
        this.password.permissionGroupIds = this.password.permissionGroupIds.filter(g => !g.startsWith('private:'))
      }
    },
    close() {
      if (!this.isEditing) this.clearPw();
      this.dialog = false;
      this.resetValidation();
    },
    formatDate(ts) {
      let date = new Date();
      date.setTime(ts * 1000);
      return date.toLocaleString("de-DE");
    },
    deleteGroupChip(itemToRemove) {
      // make a shallow clone of the list, because it might still be referenced to this.currentPassword
      this.password.permissionGroupIds.splice(this.password.permissionGroupIds.indexOf(itemToRemove), 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.po-password-strength-bar {
  margin-top: -22px;
}

.not-in-groups {
  background-color: #EEEEEE;
}

.privateSelector {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
