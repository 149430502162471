<template>
  <v-dialog v-model="pwGeneratorDialogProp" persistent max-width="650">
    <v-card>
      <v-card-title class="headline">
        Password Generator
      </v-card-title>
      <v-card-text>
        <PasswordGenerator :generatedPassword.sync="generatedPassword" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>        
        <v-btn color="green darken-1" text @click="close">
          Close
        </v-btn>
        <v-btn color="green darken-1" text @click="copy" v-if="generatedPassword">
          Copy to form
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </v-row> -->
</template>

<script>
import PasswordGenerator from "./PasswordGenerator";

export default {
  data() {
    return {
      generatedPassword: "",
    };
  },
  components: {
    PasswordGenerator
  },
  props: {
    pwGeneratorDialogProp: {
      default: false,
    },
    currentPasswordProp: {
      default: "",
    },
  },
  methods: {
    copy() {
      //this.currentPassword = ;
      this.$emit("update:currentPasswordProp", this.generatedPassword);
      this.close();
    },
    close() {
      console.log("close");
      this.$emit("update:pwGeneratorDialogProp", false);
    },
  },
};
</script>
