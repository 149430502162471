<template>
  <v-container class="mt-0">
    <v-row class="mt-0" align="center" justify="center">
      <v-data-table :headers="headers" :items="items" :item-class="passwordRowClass" sort-by="Company" class="elevation-1" :search="search" :loading="loading" mobile-breakpoint="750" dense>
        <!-- v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10" -->
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col>
                <h3 class="ml-3">{{ titleTable }}</h3>
              </v-col>
              <v-col class="d-flex justify-end mr-3" v-if="createBtn != 'false'">
                <PopupEditPassword />
              </v-col>
            </v-row>
            <!-- <v-divider></v-divider> -->
            <!-- <v-row> -->
            <!-- <v-toolbar flat color="white"> -->
            <!-- <v-toolbar-title>Passwords</v-toolbar-title> -->

            <v-row class="mt-n5">
              <!-- <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field> -->
              <v-col cols="12" md="4" sm="4" xs="12">
                <v-text-field class="ml-2 mr-2" single-line v-model="filter.company" type="text" append-icon="mdi-magnify" label="Company..."></v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="4" xs="12">
                <v-text-field class="ml-2 mr-2" single-line v-model="filter.info" type="text" append-icon="mdi-magnify" label="Info..."></v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="4" xs="12">
                <v-text-field class="ml-2 mr-2" single-line v-model="filter.mailLogin" type="text" append-icon="mdi-magnify" label="Login..."></v-text-field>
              </v-col>

              <!-- <v-spacer></v-spacer> -->

              <!-- <v-col class="ml-2"> -->

              <!-- </v-col> -->
            </v-row>
          </v-container>
          <!-- </v-toolbar> -->
          <!-- </v-row> -->
        </template>
        <template v-slot:item.domain="{ item }">
          <div v-if="item.domain" class="mr-n4">
            <!-- class="p-2" -->
            <v-img :src="'https://www.google.com/s2/favicons?domain=' + item.domain" height="18px" width="18px"></v-img>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="ml-1" @click="getLogin(item)" v-on="on" v-bind="attrs">mdi-account</v-icon>
            </template>
            <span> Copy Login ({{ item.mailLogin }}) </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="ml-1" @click="getKey(item.id)" v-on="on" v-bind="attrs">mdi-key</v-icon>
            </template>
            <span> Copy Password </span>
          </v-tooltip>

          <PopupEditPassword :currentPassword="item" />
          <!--   v-on="on"
                v-bind="attrs"  -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <v-icon v-if="isFavorite(item.id)" small class="ml-0" @click="removeFavorite(item.id)">mdi-star</v-icon>
                <v-icon v-else small class="ml-0" @click="addFavorite(item.id)">mdi-star-outline</v-icon>
              </span>
            </template>
            <span> Favorites </span>
          </v-tooltip>

          <!-- -- -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="ml-1"
                @click="getLink(item)"
                v-on="on"
                v-bind="attrs"
                :style="{
                  visibility: item.link ? 'visible' : 'hidden',
                }"
                >mdi-open-in-new</v-icon
              >
            </template>
            <span> Open Link </span>
          </v-tooltip>
          <!-- -- -->

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="ml-1 pb-2 pt-1"
                v-bind="attrs"
                v-on="on"
                :style="{
                  visibility: item.twoFactorInfo ? 'visible' : 'hidden',
                }"
                >mdi-two-factor-authentication</v-icon
              >
            </template>
            <span> 2FA Info (keys etc.): {{ item.twoFactorInfo }} </span>
          </v-tooltip>
          <!-- <v-icon small class="ml-2" @click="logItem(item)">mdi-two-factor-authentication</v-icon> -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="ml-1 pb-2 pt-1"
                @click="getBackupCode(item)"
                v-bind="attrs"
                v-on="on"
                :style="{
                  visibility: item.twoFactorBackupCodes && !!item.twoFactorBackupCodes[0] ? 'visible' : 'hidden',
                }"
                >mdi-numeric</v-icon
              >
            </template>
            <span> Get one backup code </span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="ml-1 pb-2 pt-1"
                @click="getOtpCode(item.id)"
                v-bind="attrs"
                v-on="on"
                :style="{
                  visibility: item.otpEnabled ? 'visible' : 'hidden',
                }"
                >mdi-qrcode</v-icon
              >
            </template>
            <span> Get Authenticator OTP code </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <v-snackbar :timeout="snackbarTimeout" v-model="snackbar" elevation="10" shaped>
        {{ snackbarText }}
        <v-btn color="info" @click.native="snackbar = false" text>Close</v-btn>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'pinia';
import { fireFunctions } from "../store/database";
import { usePasswordStore } from "../store/Password";
import { useUserStore } from '../store/User';
import PopupEditPassword from "./popups/PopupEditPassword";

export default {
  name: "tablePwComponent",
  props: ["items", "titleTable", "createBtn"],
  data: () => ({
    // loading: false,
    newItem: false,
    filter: {
      company: "",
      info: "",
      mailLogin: "",
    },
    search: "",
    editDialog: false,
    dialog: false,
    snackbarText: "",
    snackbar: false,
    snackbarTimeout: 2000,
  }),
  components: {
    PopupEditPassword,
    // PopupCreatePassword,
  },
  computed: {
    ...mapState(usePasswordStore, ["loading"]),
    ...mapState(useUserStore, ["currentUserDb", "currentUserMyDb"]),
    headers() {
      return [
        { text: "", value: "domain", sortable: false, width: "18px" },
        {
          text: "Company",
          value: "company",
          sortable: true,
          width: "160px",
          filter: (value) => {
            if (!this.filter.company) return true;
            if (!value) return false;
            return value.toLowerCase().includes(this.filter.company.toLowerCase());
          },
        },
        {
          text: "Info",
          value: "info",
          sortable: true,
          width: "160px",
          filter: (value) => {
            if (!this.filter.info) return true;
            if (!value) return false;
            return value.toLowerCase().includes(this.filter.info.toLowerCase());
          },
        },
        {
          text: "Login Account",
          value: "mailLogin",
          sortable: true,
          width: "160px",
          filter: (value) => {
            if (!this.filter.mailLogin) return true;
            if (!value) return false;
            return value.toLowerCase().includes(this.filter.mailLogin.toLowerCase());
          },
        },
        // { text: "Passwrort", value: "pw", sortable: false },
        { text: "Actions", value: "actions", sortable: false, width: "200px" },
      ];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {

  },

  methods: {
    passwordRowClass(item) {
        if (item && item.private) {
          return "private"; //can also return multiple classes e.g ["orange","disabled"]
        }

        return "";
    },
    logItem(item) {
      console.log(item);
    },
    showSnackbar(text, timeout = 2000) {
      console.log("showSnackbar", text);
      this.snackbarText = text;
      this.snackbar = true;
      this.snackbarTimeout = timeout;
    },
    setloading(loadingStatus) {
      usePasswordStore().loading = loadingStatus;
    },

    addFavorite(id) {
      usePasswordStore().changeFavorite({
        pwId: id,
        isToAdd: true,
      });
    },
    removeFavorite(id) {
      usePasswordStore().changeFavorite({
        pwId: id,
        isToAdd: false,
      });
    },
    isFavorite(id) {
      return this.currentUserMyDb && this.currentUserMyDb.favorites && this.currentUserMyDb.favorites.includes(id);
    },

    deleteItem(itemId) {
      // const index = this.passwords.indexOf(item);
      // confirm("Are you sure you want to delete this item?") &&
      //   this.passwords.splice(index, 1);
      var r = confirm("Passwort löschen?");
      if (r == true) {
        usePasswordStore().deletePw(itemId);
        this.showSnackbar("deleted element");
      }
    },
    getBackupCode(item) {
      const backupCodeArray = item.twoFactorBackupCodes.split(/\n/);
      const backupcode = backupCodeArray.shift();
      item.twoFactorBackupCodes = backupCodeArray.length > 0 ? backupCodeArray.join("\n") : "";
      usePasswordStore().updatePw(item);
      this.copyTextToClipboard(backupcode);
      this.showSnackbar("copied backup code");
    },
    getOtpCode(itemKey) {
      usePasswordStore().loading = true;
      var getOpt = fireFunctions.httpsCallable("password-getOtp");
      getOpt(itemKey)
        .then((r) => {
          let code = r.data;
          if (code) {
            this.copyTextToClipboard(code);
            this.showSnackbar(`Authenticator OTP Code: ${code}`, 8000);
          } else {
            this.showSnackbar("No Authenticator OTP Code found.");
          }
        })
        .catch((error) => {
          console.error(error);
          this.showSnackbar(error); // TODO funktioniert nicht;
        })
        .finally(() => usePasswordStore().loading = false);
    },
    getKey(itemKey) {
      usePasswordStore().loading = true;
      var getKey = fireFunctions.httpsCallable("getKey");
      getKey(itemKey)
        .then((r) => {
          let pw = r.data;
          if (pw) {
            this.copyTextToClipboard(pw);
            this.showSnackbar("copied password ");
          } else {
            this.showSnackbar("no password set");
          }
        })
        .catch((error) => {
          console.error(error);
          this.showSnackbar(error); // TODO funktioniert nicht;
        })
        .finally(() => usePasswordStore().loading = false);
    },
    getLogin(item) {
      this.copyTextToClipboard(item.mailLogin);
      this.showSnackbar("copied login");
    },

    getLink(item) {
      // this.copyTextToClipboard(item.link);
      window.open(item.link, "_blank");
    },

    //todo vermutlich nicht gebraucht
    // close() {
    //   this.dialog = false;
    //   this.$nextTick(() => {
    //     console.log("test");
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },

    // save() { //todo vermutlich nicht gebraucht
    //   if (this.editedIndex > -1) {
    //     Object.assign(this.passwords[this.editedIndex], this.editedItem);
    //   } else {
    //     this.passwords.push(this.editedItem);
    //   }
    //   this.close();
    // },

    // todo noch nicht genutzt / fertig
    loadMore: function () {
      this.busy = true;

      setTimeout(() => {
        this.busy = false;
      }, 1000);
    },
  },
};
</script>

<style>
tr.private {
  font-style: italic;
  background-color: #E2E2E2;
}
</style>
