import Vue from "vue";
import VueRouter from "vue-router";
import PWTable from "../components//PWTable.vue";
import LogTable from "../components/LogTable.vue";
import Settings from "../components/Settings.vue";
import Login from "@/components/auth/Login.vue";
import { useUserStore } from "../store/User";
import firebase from 'firebase/app';

Vue.use(VueRouter);

const routes = [{
    path: "/auth/Login",
    name: "Login",
    component: Login,
  },
  {
    path: '/',
    redirect: '/PWTable',
    component: PWTable,
    meta: {
      requiresAuth: true,
    },
  },
  // { path: '/', 
  // redirect: '/auth/Login' },
  {
    path: "/PWTable",
    name: "PWTable",
    component: PWTable,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/LogTable",
    name: "LogTable",
    component: LogTable,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/Settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function (to, from, next) {
  let user = firebase.auth().currentUser;

  // console.log('router')
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // console.log(store.getters['User/isLoggedIn']);
    if (!user) {
      next({
        name: "Login",
        query: {
          redirect: to.fullPath
        },
      });
    } else {
      next();
    }
  } else {
    if (!user && from.path != '/auth/Login' && to.path !== '/auth/Login') {
      console.log('toLogin');
      next({
        path: "/auth/Login",
        query: {
          redirect: to.fullPath
        },
      });
    } else {
      // console.log('next')
      next();
    }
    // next(); // make sure to always call next()!
  }
});

export default router;