import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.formattedLogs,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VRow,[_c(VCol,[_c(VTextField,{staticClass:"mx-4",attrs:{"label":"Search User Mail in Firestore..."},model:{value:(_vm.searchUser),callback:function ($$v) {_vm.searchUser=$$v},expression:"searchUser"}})],1),_c(VCol,[_c(VBtn,{staticClass:"primary",on:{"click":_vm.updateWithSearch}},[_vm._v(" Search ")])],1)],1),_c(VRow,[_c(VCol,[_c(VTextField,{staticClass:"mx-4",attrs:{"label":"Search locally ..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }